/* eslint-disable no-unused-vars */
<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'

import Layout from '../../../layouts/main'
import appConfig from '@/app.config'
import Multiselect from 'vue-multiselect'
import service from '@/server/http_service'
import Buttons from '../../../pages/ui/buttons.vue'
import global_func from '@/global_function'
import i18n from '@/i18n'
import vue2Dropzone from 'vue2-dropzone'
import geo_data from '@/assets/file/geo_data.JSON'
import Swal from 'sweetalert2'
import policy from '@/components/modal/policy'
import simplebar from 'simplebar-vue'

/**
 * Form wizard component
 */
export default {
  page: {
    title: i18n.t('register_goods_and_tran.title'),
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    FormWizard,
    TabContent,
    WizardButton,
    Multiselect,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    Buttons,
    policy,
    simplebar,
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_data = i18n.t('register_goods_and_tran')
    var text_terms = i18n.t('text_terms')
    let user_data = JSON.parse(user)
    var mail = ''
    var mail_dis = false
    if(user_data[0].mailaddr != '' && user_data[0].mailaddr != null) {
      mail = user_data[0].mailaddr
      mail_dis = true
    }
    return {
      account_lang: lang,
      account_data: user_data,
      lang_data: lang_data,
      goods_lang: true,
      geo_data: geo_data,
      // title tabs
      title: lang_data.general.title,
      title2: lang_data.general.title2,
      title3: lang_data.general.title3,
      title4: lang_data.general.title4,
      // information tab
      formData: {
        firstname: '',
        lastname: '',
        mover_name: '',
        mover_grant_num: '',
        id_card: '',
        mover_tel: '',
        mailaddr: mail,
        mover_address1: '',
      },
      mail_dis_status: mail_dis,
      dataBusinessType_master:[],
      dataBusinessType_sel:[],
      dataBusinessType_label:'',
      disable_zipcode_input: false,
      placeholder: {
        // information tab
        name: lang_data.information.placeholder.name,
        lastname: lang_data.information.placeholder.lastname,
        mover_type: lang_data.information.placeholder.mover_type,
        mover_name: lang_data.information.placeholder.mover_name,
        mover_grant_num: '',
        id_card: '',
        mover_tel: lang_data.information.placeholder.mover_tel,
        mailaddr: lang_data.information.placeholder.mailaddr,
        mover_address: lang_data.information.placeholder.mover_address,
        mover_province: lang_data.information.placeholder.mover_province,
        mover_district: lang_data.information.placeholder.mover_district,
        mover_district2: lang_data.information.placeholder.mover_district2,
        mover_postcode: lang_data.information.placeholder.mover_postcode,
        // data new truck
        group: lang_data.add_car.placeholder.group,
        type: lang_data.add_car.placeholder.type,
        numberOfCar: lang_data.add_car.placeholder.numberOfCar,
        car: lang_data.add_car.placeholder.car,
        tons: lang_data.add_car.placeholder.tons,
        // doc tab
        id_card_copy: lang_data.doc.placeholder.id_card_copy,
        pic: lang_data.doc.placeholder.mover_picpostcode,
      },
      valid_msg: {
        mover_grant_num: '',
      },
      nextButtonText: lang_data.general.btn.next,
      backButtonText: lang_data.general.btn.back,
      fin_btn: lang_data.general.btn.fin,
      save_succes: lang_data.general.save_succes,
      del_btn: 'ลบข้อมูล',
      mover_type: [
        { name: lang_data.information.mover_co, value: '1' },
        { name: lang_data.information.mover_person, value: '2' },
      ],
      alert_msg: lang_data.alert_msg,
      mover_type_sel: [],
      dataProvince: [],
      dataAmphur: [],
      dataTambol: [],
      dataProvince_data: [],
      dataAmphur_data: [],
      dataTambol_data: [],
      dataProvince_sel: [],
      dataAmphur_sel: [],
      dataTambol_sel: [],
      zipcode: '',
      input_display: false,
      input_display2: false,
      input_display3: false,
      row_display: false,
      next_btn: true,
      disableEdit: false,
      // terms tab
      text:text_terms.text,
      // goods type tab
      transporter_lang: false,
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      dataGoodsType_data2: [],
      dataGoodsType_ck: [],
      dataGoodsType_sel: [],
      dataGoodsType_note: '',
      dataGoodsTerms_ck: '',
      dataGoodsTerms_ck_is_Invalid: false,
      // doc tab
      file_id_card: [],
      file_pic_mover: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 1,
        acceptedFiles: 'image/*,application/pdf',
      },
      dropzoneOptions2: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        maxFiles: 3,
        acceptedFiles: 'image/*,application/pdf',
      },
      pdfFile: {}, // for test reader file
      // data truck tab
      dataTruckType_master: [],
      dataTruckTypeGroup_master: [],
      dataTruckType: [],
      dataTruckTypeGroup: [],
      LangTruckType: '',
      LangTruckTypeGroup: '',
      dataNewTruck: [],
      dataGroupTruck: [
        {
          id: '1',
          name_group: 'ทั้งหมด',
        },
      ],
      dataNewTruck_Invalid: true,
      dataNewTruck_add: [],

      // display_tab3: true,
      // display_tab4: false,
      // display_tab5: false,

      display_tab3: false,
      display_tab4: true,
      display_tab5: false,

      data_prov_data: {},
      data_prov_sel: {
        1:[],
        2:[],
        3:[],
        4:[],
        5:[],
        6:[],
      },
    }
  },
  watch: {
    dataProvince_sel: {
      handler: function() {
        var ck =  this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 =  this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 =  this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if(!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
        
      },
    },
    dataAmphur_sel: {
      handler: function() {
        var ck =  this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 =  this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 =  this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if(!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
      },
    },
    dataTambol_sel: {
      handler: function() {
        var ck =  this.dataProvince_sel === undefined || this.dataProvince_sel === null
        var ck2 =  this.dataAmphur_sel === undefined || this.dataAmphur_sel === null
        var ck3 =  this.dataTambol_sel === undefined || this.dataTambol_sel === null
        if(!ck && !ck2 && !ck3) {
          this.disable_zipcode_input = true
        } else {
          this.disable_zipcode_input = false
        }
        
      },
    },
    deep: true,
  },
  mounted() {
    this.getDataAddInfo()
    this.getBusinessType()
    this.getTruckType()
    this.getTruckTypeGroup()
    this.getDataGoods()
  },
  methods: {
    company_sel() {
      if (this.mover_type_sel !== undefined && this.mover_type_sel !== null) {
        if (this.mover_type_sel['value'] == 1) {
          this.input_display = true
          this.input_display2 = false
          this.input_display3 = true
          this.placeholder.mover_grant_num = this.lang_data.information.placeholder.mover_grant_num
          this.valid_msg.mover_grant_num = this.lang_data.information.invalid.mover_grant_num
        } else {
          this.input_display = true
          this.input_display2 = true
          this.input_display3 = false
          this.placeholder.mover_grant_num = this.lang_data.information.placeholder.id_card
          this.valid_msg.mover_grant_num = this.lang_data.information.invalid.id_card
        }
        this.next_btn = false
        this.row_display = true
      } else {
        this.next_btn = true
        this.row_display = false
        this.input_display = false
        this.input_display2 = false
        this.input_display3 = false
      }
    },
    getTruckType() {
      // var that = this
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        var data = JSON.parse(JSON.stringify(rp.data))
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          let row = data[index]
          if(row.other_flag == 0) {
            let rowdata = {
              id_group: row.id_group,
              id_model: row.id_model,
              name_group_en: row.name_group_en,
              name_group_th: row.name_group_th,
              name_model_en: row.name_model_en,
              name_model_th: row.name_model_th,
              other_flag: row.other_flag,
              prices_pre_km: row.prices_pre_km,
              prices_start: row.prices_start,
              status: '',
            }
            this.dataTruckType_master.push(JSON.parse(JSON.stringify(rowdata)))
          }
        }
        // this.dataTruckType = this.dataTruckType_master
        if (this.account_lang == 'th') {
          this.LangTruckType = 'name_model_th'
        } else {
          this.LangTruckType = 'name_model_en'
        }
        // console.log(that.dataTruckType)
      })
    },
    getTruckTypeGroup() {
      var that = this
      service.postService('getTruckTypeGroup').then(rp => {
        // console.log(rp)
        var data = rp.data
        for (let index = 0; index < data.length; index++) {
          var row = data[index]
          let rowdata = {
            id_group: row.id_group,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            status: '',
          }
          that.dataTruckTypeGroup_master.push(JSON.parse(JSON.stringify(rowdata)))
        }
        // this.dataTruckTypeGroup = that.dataTruckTypeGroup_master
        if (this.account_lang == 'th') {
          this.LangTruckTypeGroup = 'name_group_th'
        } else {
          this.LangTruckTypeGroup = 'name_group_en'
        }
        // console.log(that.dataTruckTypeGroup)
      })
    },
    getDataAddInfo() {
      this.setService_route(geo_data.province, geo_data.geographies)
      this.dataProvince = geo_data.province
      this.dataAmphur = geo_data.amphures
      this.dataTambol = geo_data.districts
      this.dataProvince_data = this.dataProvince
      this.dataAmphur_data = this.dataAmphur
      this.dataTambol_data = this.dataTambol
      // service.postService('getAddInfo').then(rp => {
      //   // console.log(rp)
      //   this.dataProvince = rp.data
      //   this.dataAmphur = rp.data2
      //   this.dataTambol = rp.data3
      //   this.setService_route(rp.data, rp.data4)
      //   // console.log(this.dataProvince)
      //   // console.log(this.dataAmphur)
      //   // console.log(this.dataTambol)
      //   this.dataProvince_data = this.dataProvince
      //   this.dataAmphur_data = this.dataAmphur
      //   this.dataTambol_data = this.dataTambol
      // })
    },
    getBusinessType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getBusinessType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var obj = {
            business_type_id: data[index]['business_type_id'],
            business_type_name_th: data[index]['business_type_name_th'],
            business_type_name_en: data[index]['business_type_name_en'],
            business_type_code: data[index]['business_type_code'],
            business_type_sel_status: '',
          }
          this.dataBusinessType_master.push(obj) // for insert data to db
          if (this.account_lang == 'th') {
            this.goods_lang = true
            this.dataBusinessType_label = 'business_type_name_th'
          } else {
            this.dataBusinessType_label = 'business_type_name_en'
          }
        }
        // console.log(this.dataBusinessType_master)
      })
    },
    province_sel() {
      if(this.zipcode == '') {
        this.dataAmphur_sel = []
        this.dataTambol_sel = []
        this.zipcode = ''
        // console.log(this.dataProvince_sel)
        let ps = this.dataProvince_sel

        let ap = this.dataAmphur
        this.dataAmphur_data = []
        this.dataTambol_data = []
        for (let index = 0; index < ap.length; index++) {
          const ap_row = ap[index]
          if (ap_row['province_id'] == ps['id']) {
            this.dataAmphur_data.push(ap_row)
          }
        }

        let ap_data = this.dataAmphur_data
        let tb = this.dataTambol
        this.dataTambol_data = []
        for (let index = 0; index < ap_data.length; index++) {
          const ap_data_row = ap_data[index]
          for (let index2 = 0; index2 < tb.length; index2++) {
            const tb_row = tb[index2]
            if (tb_row['amphure_id'] == ap_data_row['id']) {
              this.dataTambol_data.push(tb_row)
            }
          }
        }
      }
    },
    amphur_sel() {
      if(this.zipcode == '') {
        this.dataTambol_sel = []
        // this.zipcode = ''
        // console.log(this.dataAmphur_sel)
        let as = this.dataAmphur_sel
        let tb = this.dataTambol
        this.dataTambol_data = []
        for (let index = 0; index < tb.length; index++) {
          const tb_row = tb[index]
          if (tb_row['amphure_id'] == as['id']) {
            this.dataTambol_data.push(tb_row)
          }
        }
      }

    },
    tambol_sel() {
      // console.log(this.dataTambol_sel)
      this.zipcode = this.dataTambol_sel['zip_code']
    },
    zipcode_input() {
      // console.log(this.zipcode)
      var flag = 0
      if(this.zipcode.length == 5) {
        this.dataTambol_data = []
        this.dataAmphur_data = []
        this.dataProvince_data = []
        this.dataProvince_sel = []
        this.dataAmphur_sel = []
        this.dataTambol_sel = []
        for (let index = 0; index < this.dataTambol.length; index++) {
          const element = this.dataTambol[index]
          if(element['zip_code'] == this.zipcode) {
            this.dataTambol_data.push(element)
          }
        }
        for (let index2 = 0; index2 < this.dataTambol_data.length; index2++) {
          const element2 = this.dataTambol_data[index2]
          for (let index3 = 0; index3 < this.dataAmphur.length; index3++) {
            const element3 = this.dataAmphur[index3]
            if(element3['id'] == element2['amphure_id']) {
              if(this.dataAmphur_data.length > 0) {
                for (let index4 = 0; index4 < this.dataAmphur_data.length; index4++) {
                  const element4 = this.dataAmphur_data[index4]
                  if(element4['id'] == element3['id']) {
                    flag++
                  }
                  if(flag == 0) {
                    this.dataAmphur_data.push(element3)
                  }
                }
              } else {
                this.dataAmphur_data.push(element3)
              }
            }
          }
        }
        for (let index5 = 0; index5 < this.dataAmphur_data.length; index5++) {
          const element5 = this.dataAmphur_data[index5]
          for (let index6 = 0; index6 < this.dataProvince.length; index6++) {
            const element6 = this.dataProvince[index6]
            if(element5['province_id'] == element6['id']) {
              this.dataProvince_data.push(element6)
            }
          }
        }
        // console.log(this.dataTambol_data)
        // console.log(this.dataAmphur_data)
        // console.log(this.dataProvince_data)
        this.dataProvince_sel = this.dataProvince_data[0]
        this.dataAmphur_sel = this.dataAmphur_data[0]
        this.dataTambol_sel = this.dataTambol_data[0]
      } else {
        this.dataProvince_data = this.dataProvince
        this.dataAmphur_data = this.dataAmphur
        this.dataTambol_data = this.dataTambol
      }
    },
    setService_route(data, data2) {
      var prov = data
      // eslint-disable-next-line no-unused-vars
      var geo = data2
      var data_prov = {
        1: [
          {
            id: '101',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '1',
            status: '',
          },
        ],
        2: [
          {
            id: '102',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '2',
            status: '',
          },
        ],
        3: [
          {
            id: '103',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '3',
            status: '',
          },
        ],
        4: [
          {
            id: '104',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '4',
            status: '',
          },
        ],
        5: [
          {
            id: '105',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '5',
            status: '',
          },
        ],
        6: [
          {
            id: '106',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '6',
            status: '',
          },
        ],
      }
      // console.log(prov)
      for (let index = 0; index < prov.length; index++) {
        let rowdata = {
          id: prov[index].id,
          name_th: prov[index].name_th,
          name_en: prov[index].name_en,
          geography_id: prov[index].geography_id,
          status: '',
        }
        const rowProv = rowdata
        if (rowProv.geography_id == '1') {
          data_prov['1'].push(rowProv)
        } else if (rowProv.geography_id == '2') {
          data_prov['2'].push(rowProv)
        } else if (rowProv.geography_id == '3') {
          data_prov['3'].push(rowProv)
        } else if (rowProv.geography_id == '4') {
          data_prov['4'].push(rowProv)
        } else if (rowProv.geography_id == '5') {
          data_prov['5'].push(rowProv)
        } else if (rowProv.geography_id == '6') {
          data_prov['6'].push(rowProv)
        }
      }
      // console.log(data_prov)
      this.data_prov_data = data_prov
      // this.data_prov_data2 = data_prov
      // this.data_prov_data3 = data_prov
      // this.data_prov_data4 = data_prov
      // this.data_prov_data5 = data_prov
      // this.data_prov_data6 = data_prov
      if (this.account_lang == 'th') {
        this.transporter_lang = true
      }
    },
    checkMoverName() {
      if (this.mover_type_sel !== undefined && this.mover_type_sel !== null) {
        if (this.formData.mover_name == '') {
          // this.formData.mover_name = this.lang_data.general.nodata
          this.formData.mover_name = this.account_data[0].name
        }
      }
    },
    async beforeTabSwitch() {
      //เช็ค Valide ก่อนจะกด ถัดไป
      await this.checkMoverName()
      const isValid = await this.$refs.register_goods_and_tran_form.validate()
      // console.log(isValid)
      // console.log(this.mover_type_sel['name'])
      // console.log(this.mover_type_sel)
      // console.log(this.dataProvince_sel)
      // console.log(this.dataAmphur_sel)
      // console.log(this.dataTambol_sel)
      // console.log(this.mover_type_sel !== undefined && this.mover_type_sel !== null)
      if (!isValid) {
        if (this.mover_type_sel !== undefined && this.mover_type_sel !== null) {
          if (this.mover_type_sel['value'] == 2) {
            if (this.formData.mover_name == '') {
              this.formData.mover_name = this.lang_data.general.nodata
              const isValid2 = this.$refs.register_goods_and_tran_form.validate()
              if (isValid2) {
                this.dataGoodsTerms_ck = ''
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        }
        return false
      } else {
        this.dataGoodsTerms_ck = ''
        return true
      }
    },
    async beforeTabSwitch2() {
      //เช็ค Valide ก่อนจะกด ถัดไป tab3
      if (this.dataGoodsTerms_ck == 'checked') {
        this.dataGoodsTerms_ck_is_Invalid = false
        this.dataNewTruck = []
        return true
      } else {
        this.dataGoodsTerms_ck_is_Invalid = true
        return false
      }
      // if( this.dataGoodsTerms_ck == 'checked') {
      //   return true
      // } else {
      //   return false
      // }
    },
    beforeTabSwitch3() {
      // เปิด tab เส้นทางบริการ และ เช็ค vaild payload
      if (this.dataNewTruck.length > 0) {
        var invalid = 0
        for (let index = 0; index < this.dataNewTruck.length; index++) {
          const rowData = this.dataNewTruck[index]
          if (rowData.payload == '') {
            this.dataNewTruck[index].invalid = true
            invalid++
          } else {
            this.dataNewTruck[index].invalid = false
          }
        }
        if(invalid > 0){
          return false
        } else {
          // if(this.display_tab3) {
          //   this.getDataAddInfo()
          //   this.display_tab3 = false
          //   this.display_tab4 = true
          //   this.display_tab5 = false
          // } else if(this.display_tab4) {
          //   this.display_tab3 = false
          //   this.display_tab4 = false
          //   this.display_tab5 = true
          // } else if(this.display_tab5) {
          //   return true
          // }
          if(this.display_tab4) {
            this.display_tab3 = false
            this.display_tab4 = false
            this.display_tab5 = true
          } else if(this.display_tab5) {
            return true
          }
        }
      } else {
        // if (this.display_tab3) {
        //   this.getDataAddInfo()
        //   this.display_tab3 = false
        //   this.display_tab4 = true
        //   this.display_tab5 = false
        // } else if(this.display_tab4){
        //   this.getDataGoodsTypeLocal()
        //   this.display_tab3 = false
        //   this.display_tab4 = false
        //   this.display_tab5 = true
        // } else if(this.display_tab5) {
        //   this.$refs.dropzone_id_card_copy.dropzone.files = []
        //   this.$refs.dropzone_pic_mover.dropzone.files = []
        //   return true
        // }
        if(this.display_tab4){
          this.getDataGoodsTypeLocal()
          this.display_tab3 = false
          this.display_tab4 = false
          this.display_tab5 = true
        } else if(this.display_tab5) {
          this.$refs.dropzone_id_card_copy.dropzone.files = []
          this.$refs.dropzone_pic_mover.dropzone.files = []
          return true
        }
      }
      
    },
    backTabSwitch3() {
      // ฟังก์ชั่นกดย้อนกลับ tab3

      // if(this.display_tab5) {
      //   this.display_tab3 = false
      //   this.display_tab4 = true
      //   this.display_tab5 = false
      // } else if(this.display_tab4) {
      //   this.display_tab3 = true
      //   this.display_tab4 = false
      //   this.display_tab5 = false
      // }
      if(this.display_tab5) {
        this.display_tab3 = false
        this.display_tab4 = true
        this.display_tab5 = false
      } else if(this.display_tab4) {
        this.display_tab3 = false
        this.display_tab4 = false
        this.display_tab5 = false
      }
    },
    ckInvaildPayload(index) {
      var payload = this.dataNewTruck[index].payload
      if(payload == ''){
        this.dataNewTruck[index].invalid = true
      } else {
        this.dataNewTruck[index].invalid = false
      }
    },
    getDataGoods() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          if (index < 7) {
            this.dataGoodsType_data.push(goods_type_sel)
          } else {
            this.dataGoodsType_data2.push(goods_type_sel)
          }
          if (this.account_lang == 'th') {
            this.transporter_lang = true
          }
        }
        // console.log(this.dataGoodsType_data2)
      })
    },
    getDataGoodsTypeLocal() {
      let data = this.dataGoodsType_master
      this.dataGoodsType_data = []
      this.dataGoodsType_data2 = []
      for (let index = 0; index < data.length; index++) {
        var goods_type_sel = {
          goods_type_id: data[index]['goods_type_id'],
          goods_type_name_th: data[index]['goods_type_name_th'],
          goods_type_name_en: data[index]['goods_type_name_en'],
          goods_type_status: '',
        }
        if (index < 7) {
          this.dataGoodsType_data.push(goods_type_sel)
        } else {
          this.dataGoodsType_data2.push(goods_type_sel)
        }
        if (this.account_lang == 'th') {
          this.transporter_lang = true
        }
      }
    },
    nextTab() {
      // console.log(this.dataGoodsType_data2)
    },
    async sendDataRegister() {
      // var valideTab1 = await this.beforeTabSwitch()
      // var valideTab2 = true
      // // console.log(valideTab1 + ' ' + valideTab2)
      // global_func.LoadMsg(this.alert_msg.loading,'','')
      // console.log(this.file_id_card)
      // if (valideTab1) {
      //   if (valideTab2) {
      //     var thisVue = this
      //     if(this.fileReader()) {
      //       setTimeout(function() {
      //         thisVue.addNewData()
      //       }, 1500)
      //     } else {
      //       global_func.CloseMsg()
      //       global_func.alertMessage(this.alert_msg.upload + '<br />' + this.alert_msg.upload2, '', 'warning')
      //     }
      //   } else {
      //     global_func.CloseMsg()
      //     global_func.alertMessage(this.alert_msg.terms, '', 'warning')
      //   }
      // } else {
      //   global_func.CloseMsg()
      //   global_func.alertMessage(this.alert_msg.info, '', 'warning')
      // }
      Swal.fire({
        title: 'ยืนยันการกรอกรายละเอียด ใช่ หรือ ไม่',
        text: '',
        icon: 'warning',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่',
        allowOutsideClick: true,
        showCancelButton: true,
        showCloseButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          global_func.LoadMsg(this.alert_msg.loading,'','')
          // console.log(this.file_id_card)
          var thisVue = this
          if(this.fileReader()) {
            setTimeout(function() {
              thisVue.addNewData()
            }, 1500)
          } else {
            global_func.CloseMsg()
            global_func.alertMessage(this.alert_msg.upload + '<br />' + this.alert_msg.upload2, '', 'warning')
          }
        } else if (result.isDenied) {
          return
        }
      })
    },
    addNewVehicle() {
      // เพิ่มข้อมูลรถ
      let dataPush = {
        group: '',
        type: '',
        lcp: '',
        numberOfCar: '',
        payload: '',
        invalid: false,
      }
      this.dataNewTruck.push(dataPush)
      if(this.dataNewTruck.length > 0) {
        this.dataTruckType[this.dataNewTruck.length - 1] = this.dataTruckType_master
        this.dataTruckTypeGroup[this.dataNewTruck.length - 1] = this.dataTruckTypeGroup_master
      }
    },
    testTruckData() {
      // เพิ่มข้อมูลรถ
      // console.log(this.dataNewTruck)
    },
    addNewData() {
      // console.log(this.file_id_card)
      // console.log(this.file_pic_mover)
      let newData = this.dataGoodsType_data
      let newDataObj = []
      for (let index = 0; index < newData.length; index++) {
        const element = newData[index]
        if(element.goods_type_id != '1') {
          newDataObj.push(element)
        }
      }
      this.dataGoodsType_data = []
      this.dataGoodsType_data = newDataObj

      if(this.dataBusinessType_sel == undefined || this.dataBusinessType_sel.length == 0) {
        this.dataBusinessType_sel = ''
      }
      if(this.dataProvince_sel == undefined || this.dataProvince_sel.length == 0) {
        this.dataProvince_sel = ''
      }
      if(this.dataAmphur_sel == undefined || this.dataAmphur_sel.length == 0) {
        this.dataAmphur_sel = ''
      }
      if(this.dataTambol_sel == undefined || this.dataTambol_sel.length == 0) {
        this.dataTambol_sel = ''
      }
      if(this.zipcode == undefined) {
        this.zipcode = ''
      }

      service
        .postService('editGoodsAndTran', {
          firstname: this.formData.firstname,
          lastname: this.formData.lastname,
          mover_name: this.formData.mover_name,
          mover_grant_num: this.formData.mover_grant_num,
          id_card: this.formData.id_card,
          mover_tel: this.formData.mover_tel,
          mailaddr: this.formData.mailaddr,
          mover_address1: this.formData.mover_address1,
          mover_type_sel: this.mover_type_sel,
          dataProvince_sel: this.dataProvince_sel,
          dataAmphur_sel: this.dataAmphur_sel,
          dataTambol_sel: this.dataTambol_sel,
          zipcode: this.zipcode,
          file_id_card: this.file_id_card,
          file_pic_mover: this.file_pic_mover,
          account_lang: this.account_lang,
          account_data: this.account_data,
          data_prov_data: this.data_prov_data,
          dataNewTruck: this.dataNewTruck,
          dataGoodsType_ck: this.dataGoodsType_ck,
          dataGoodsType_note: this.dataGoodsType_note,
          dataGoodsType_master: this.dataGoodsType_master,
          dataBusinessType_sel: this.dataBusinessType_sel,
          dataGoodsType_data: this.dataGoodsType_data,
          dataGoodsType_data2: this.dataGoodsType_data2,
        })
        // eslint-disable-next-line no-unused-vars
        .then(rp => {
          // console.log(rp)
          this.getUserData()
          // global_func.alertMessage(this.save_succes, '', 'success')
          // // this.prefixData = rp.data
          // setTimeout(() => {
          //   this.backToIndex()
          // }, 1500)
          // this.$router.push({ name: 'routename' })
        })
    },
    getUserData() {
      var account_data = this.account_data
      var user_name = account_data[0].user_name
      var password = account_data[0].password
      service.postService('get_user', { user_name, password }).then(rp => {
        // console.log(rp)
        if (rp.status == 'ok') {
          global_func.alertMessage( this.save_succes , '', 'success')
          localStorage.removeItem('users')
          localStorage.setItem('users', JSON.stringify(rp.data))
          var that = this
          setTimeout(() => {
            that.backToIndex()
          }, 1500)
          // this.$router.push({ name: 'routename' })
        } else {
          // alert('error')
          // eslint-disable-next-line no-undef
          // reject('These credentials do not match our records.')
          this.showDismissibleAlert = true
        }
      })
    },
    keyThaiEng: function($event) {
      // func เช็คคำ เฉพาะ ไทย - อังกฤษ
      var k = $event.keyCode
      if ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k >= 161 && k <= 255) || (k >= 3585 && k <= 3675)) {
        return true
      } else {
        $event.preventDefault()
      }
    },
    testFileReader() {
      this.$refs.dropzone_id_card_copy.dropzone.files = []
      var file = this.$refs.dropzone_id_card_copy.dropzone
      // console.log(file)
      for (let index = 0; index < file.length; index++) {
        let reader = new FileReader()
        const rowFile = file[index]
        reader.addEventListener(
          'load',
          () => {
            this.pdfFile[index] = reader.result
            // console.log(this.pdfFile)
          },
          false,
        )
        reader.readAsDataURL(rowFile)
      }
    },
    fileReader() {
      // reader id_card file
      var file_id_card = this.$refs.dropzone_id_card_copy.dropzone.files
      var thisVue = this
      var flag = 0
      if(file_id_card.length > 0) {
        this.file_id_card = []
        for (let index = 0; index < file_id_card.length; index++) {
          let reader = new FileReader()
          const rowFile = file_id_card[index]
          // console.log(rowFile)
          reader.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile.name,
                data: reader.result,
                type: rowFile.type,
              }
              thisVue.file_id_card.push(dataPush)
            // console.log(this.pdfFile)
            },
            false,
          )
          if(rowFile.status == 'success'){ 
            reader.readAsDataURL(rowFile)
          } else {
            flag++
          }
        
        }
      }

      // reader pic mover file
      var file_pic_mover = this.$refs.dropzone_pic_mover.dropzone.files
      if(file_pic_mover.length > 0) {
        this.file_pic_mover = []
        for (let index = 0; index < file_pic_mover.length; index++) {
          let reader2 = new FileReader()
          const rowFile2 = file_pic_mover[index]
          reader2.addEventListener(
            'load',
            () => {
              let dataPush = {
                name: rowFile2.name,
                data: reader2.result,
                type: rowFile2.type,
              }
              thisVue.file_pic_mover.push(dataPush)
            // console.log(this.pdfFile)
            },
            false,
          )
          if(rowFile2.status == 'success'){ 
            reader2.readAsDataURL(rowFile2)
          } else {
            flag++
          }
        }
      }

      if(flag == 0) {
        return true
      } else {
        return false
      }

    },
    backToIndex() {
      // this.$router.push({ path: '/' })
      location.reload()
    },
    delDataTruck() {
      this.dataNewTruck.pop()
    },
    truck_type_group_sel(indexTruck) {
      var indexSel = indexTruck
      // console.log(indexSel)
      // var truckTypeGroup = this.dataTruckTypeGroup[indexSel]
      var dataTruck = this.dataNewTruck[indexSel]
      var dataTruckTypeGroup = dataTruck.group
      this.dataTruckType[indexSel] = []
      for (let index = 0; index < this.dataTruckType_master.length; index++) {
        const row = this.dataTruckType_master[index]
        if(row.id_group == dataTruckTypeGroup.id_group) {
          let rowdata = {
            id_group: row.id_group,
            id_model: row.id_model,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            name_model_en: row.name_model_en,
            name_model_th: row.name_model_th,
            other_flag: row.other_flag,
            prices_pre_km: row.prices_pre_km,
            prices_start: row.prices_start,
            status: '',
          }
          this.dataTruckType[indexSel].push(JSON.parse(JSON.stringify(rowdata)))
        }
      }
    },
    ck_all_sel(id,optionId) {
      // console.log(optionId)
      // console.log(this.data_prov_data)
      if (this.data_prov_data[id][0].status == 'checked') {
        for (let index = 0; index < this.data_prov_data[id].length; index++) {
          if(this.data_prov_data[id][index].id != optionId) {
            this.data_prov_data[id][index].status = 'checked'
          }
          
        }
      } else {
        for (let index = 0; index < this.data_prov_data[id].length; index++) {
          if(this.data_prov_data[id][index].id != optionId) {
            this.data_prov_data[id][index].status = ''
          }
        }
      }

    },
    ck_sel(id) {
      // console.log(id)
      // console.log(this.data_prov_data)
      var flag = 0
      for (let index = 0; index < this.data_prov_data[id].length; index++) {
        const element = this.data_prov_data[id][index]
        if(element.status != 'checked' && element.id != ('10' + id)) {
          flag++
        }
      }
      if(flag != 0) {
        this.data_prov_data[id][0].status = ''
      } else {
        this.data_prov_data[id][0].status = 'checked'
      }
    },
    // eslint-disable-next-line no-unused-vars
    ck_all_sel_goods(data) {
      // console.log(this.dataGoodsType_data)
      // console.log(this.dataGoodsType_data2)
      // console.log(data)
      // var data_sel = data
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        if(this.dataGoodsType_sel == 'checked') {
          this.dataGoodsType_data[index].goods_type_status = 'checked'
        } else {
          this.dataGoodsType_data[index].goods_type_status = ''
        }
      }

      for (let index2 = 0; index2 < this.dataGoodsType_data2.length; index2++) {
        if(this.dataGoodsType_sel == 'checked') {
          this.dataGoodsType_data2[index2].goods_type_status = 'checked'
        } else {
          this.dataGoodsType_data2[index2].goods_type_status = ''
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    ck_sel_goods(data) {
      // console.log(this.dataGoodsType_data)
      // console.log(this.dataGoodsType_data2)
      // console.log(data)
      var flag = 0
      // var data_sel = data
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        const row = this.dataGoodsType_data[index]
        if(row.goods_type_status == '') {
          flag = 1
        }
      }

      for (let index2 = 0; index2 < this.dataGoodsType_data2.length; index2++) {
        const row = this.dataGoodsType_data2[index2]
        if(row.goods_type_status == '') {
          flag = 1
        }
      }

      if(flag != 0) {
        this.dataGoodsType_sel = false
      } else {
        this.dataGoodsType_sel = 'checked'
      }
    },
  },
}
</script>

<template>
  <Layout>
    <b-container fluid>
      <h2>
        {{ $t('register_goods_and_tran.information.topic') }} <span class="font-color-blue">{{ $t('register_goods_and_tran.information.topic_blue') }}</span>
      </h2>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body custom-card-body">
              <form-wizard color="#03AEEF" error-color="#FF3C60" :next-button-text="nextButtonText" :hide-buttons="next_btn" :back-button-text="backButtonText" :finish-button-text="fin_btn">
                <tab-content :title="title" :before-change="beforeTabSwitch">
                  <ValidationObserver ref="register_goods_and_tran_form">
                    <b-row>
                      <b-col>
                        <!-- <div class="custom-control custom-checkbox">
                          <input id="customCheck1" type="checkbox" class="custom-control-input" disabled />
                          <label class="custom-control-label" for="customCheck1"><span class="font-color-blue">{{ $t('register_transporter.information.member_of_dtc') }} </span>{{ $t('register_transporter.information.member_already') }}</label>
                        </div> -->
                        <span class="font-color-red">{{ $t('register_transporter.information.info_no_have') }} </span>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="firstname">{{ $t('register_transporter.information.name') }}</label>
                          <ValidationProvider v-slot="{ failed }" name="firstname" :disabled="disableEdit" rules="">
                            <b-form-input id="firstname" v-model="formData.firstname" type="text" class="form-control" name="firstname" :placeholder="placeholder.name" :class="{ isInvalid: failed }" @keypress="keyThaiEng($event)" />
                            <b-form-invalid-feedback id="firstname-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.name') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="lastname">{{ $t('register_transporter.information.lastname') }}</label>
                          <ValidationProvider v-slot="{ failed }" name="lastname" :disabled="disableEdit" rules="">
                            <b-form-input id="lastname" v-model="formData.lastname" type="text" class="form-control" name="lastname" :placeholder="placeholder.lastname" :class="{ isInvalid: failed }" @keypress="keyThaiEng($event)" />
                            <b-form-invalid-feedback id="lastname-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.name') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="user_type">{{ $t('register_transporter.information.user_type') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="user_type" :disabled="disableEdit" rules="">
                            <multiselect v-model="mover_type_sel" label="name" :options="mover_type" :placeholder="placeholder.mover_type" :class="{ isInvalid: failed }" @input="company_sel()" />
                            <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                            <b-form-invalid-feedback id="mover_type-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_type') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6" :style="{ display: input_display3 ? '' : 'none' }">
                        <div class="form-group">
                          <label for="mover_type">{{ $t('register_transporter.information.mover_type') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="user_type" :disabled="disableEdit" rules="">
                            <multiselect v-model="dataBusinessType_sel" :label="dataBusinessType_label" :options="dataBusinessType_master" :placeholder="placeholder.mover_type" :class="{ isInvalid: failed }" @input="company_sel()" />
                            <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                            <b-form-invalid-feedback id="mover_type-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_type') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row :style="{ display: row_display ? '' : 'none' }">
                      <b-col lg="6" :style="{ display: input_display3 ? '' : '' }">
                        <div class="form-group">
                          <label for="mover_name">{{ $t('register_transporter.information.mover_name') }}</label><span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                          <ValidationProvider v-slot="{ failed }" name="mover_name" :disabled="disableEdit" rules="required">
                            <b-form-input id="mover_name" v-model="formData.mover_name" type="text" class="form-control" name="mover_name" :placeholder="placeholder.mover_name" :class="{ isInvalid: failed }" />
                            <b-form-invalid-feedback id="mover_name-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_name') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6" :style="{ display: input_display ? '' : 'none' }">
                        <div class="form-group">
                          <label for="mover_grant_num" :style="{ display: input_display3 ? 'block' : 'none' }">{{ $t('register_transporter.information.mover_grant_num') }}</label>
                          <label for="id_card" :style="{ display: input_display2 ? 'block' : 'none' }">{{ $t('register_transporter.information.id_card') }}</label>
                          <ValidationProvider v-slot="{ failed }" name="mover_grant_num" :disabled="disableEdit" rules="">
                            <b-form-input id="mover_grant_num" v-model="formData.mover_grant_num" type="number" class="form-control" name="mover_grant_num" :placeholder="placeholder.mover_grant_num" :class="{ isInvalid: failed }" />
                            <b-form-invalid-feedback id="mover_grant_num-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ valid_msg.mover_grant_num }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="mover_tel">{{ $t('register_transporter.information.mover_tel') }}</label><span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                          <ValidationProvider v-slot="{ failed }" name="id_card" :disabled="disableEdit" rules="required">
                            <b-form-input id="mover_tel" v-model="formData.mover_tel" type="number" class="form-control" name="mover_tel" :placeholder="placeholder.mover_tel" :class="{ isInvalid: failed }" />
                            <b-form-invalid-feedback id="mover_tel-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_tel') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="mailaddr">{{ $t('register_transporter.information.mailaddr') }}</label><span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                          <ValidationProvider v-slot="{ failed }" name="mailaddr" :disabled="disableEdit" rules="email|required">
                            <b-form-input id="mailaddr" v-model="formData.mailaddr" type="email" class="form-control" name="mailaddr" :placeholder="placeholder.mailaddr" :class="{ isInvalid: failed }" :disabled="mail_dis_status" />
                            <b-form-invalid-feedback id="mover_tel-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mailaddr') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="form-group">
                          <label for="mover_address1">{{ $t('register_transporter.information.mover_address') }}</label>
                          <input id="mover_address1" v-model="formData.mover_address1" type="text" class="form-control" name="mover_address1" :placeholder="placeholder.mover_address" />
                        </div>
                      </b-col>
                      <b-col lg="3">
                        <div class="form-group">
                          <label for="mover_province">{{ $t('register_transporter.information.mover_province') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="mover_province" :disabled="disableEdit" rules="">
                            <multiselect v-model="dataProvince_sel" label="name_th" :options="dataProvince_data" :placeholder="placeholder.mover_province" :class="{ isInvalid: failed }" @input="province_sel()" />
                            <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                            <b-form-invalid-feedback id="mover_province-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_province') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="3">
                        <div class="form-group">
                          <label for="mover_district">{{ $t('register_transporter.information.mover_district') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="mover_district" :disabled="disableEdit" rules="">
                            <multiselect v-model="dataAmphur_sel" label="name_th" :options="dataAmphur_data" :placeholder="placeholder.mover_district" :class="{ isInvalid: failed }" @input="amphur_sel()" />
                            <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                            <b-form-invalid-feedback id="mover_district-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_district') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="3">
                        <div class="form-group">
                          <label for="mover_district2">{{ $t('register_transporter.information.mover_district2') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="mover_district2" :disabled="disableEdit" rules="">
                            <multiselect v-model="dataTambol_sel" label="name_th" :options="dataTambol_data" :placeholder="placeholder.mover_district2" :class="{ isInvalid: failed }" @input="tambol_sel()" />
                            <!-- <v-select v-model="jobDataSel" label="job_title" :disabled="disableEdit" :options="jobData" class="form-group" :class="{ isInvalid: failed }" /> -->
                            <b-form-invalid-feedback id="mover_district2-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_district2') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="3">
                        <div class="form-group">
                          <label for="mover_postcode">{{ $t('register_transporter.information.mover_postcode') }} </label>
                          <ValidationProvider v-slot="{ failed }" name="zipcode" :disabled="disableEdit" rules="">
                            <b-form-input id="zipcode" v-model="zipcode" type="number" class="form-control" name="zipcode" :class="{ isInvalid: failed }" :disabled="disable_zipcode_input" @input="zipcode_input()" />
                            <b-form-invalid-feedback id="mover_postcode-feedback" :style="{ display: failed ? 'block' : 'none' }">{{ $t('register_transporter.information.invalid.mover_postcode') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </b-col>
                    </b-row>
                  </ValidationObserver>
                </tab-content>
                <tab-content :title="title2" :before-change="beforeTabSwitch2">
                  <h2>
                    {{ $t('register_goods_and_tran.terms.topic') }} <span class="font-color-blue">{{ $t('register_goods_and_tran.terms.topic_blue') }}</span>
                  </h2>
                  <div class="row">
                    <b-col>
                      <b-row>
                        <b-col lg="12">
                          <!-- <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="16" max-rows="16" disabled /> -->
                          <div class="policy_area">
                            <simplebar class="card-body" style="height:calc(100vh - 640px); padding: 0px 60px 0px 0px;font-size:16px">
                              <policy />
                            </simplebar>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </div>
                  <br />
                  <b-row>
                    <b-col>
                      <div class="custom-control custom-checkbox">
                        <b-form-checkbox v-model="dataGoodsTerms_ck" style="padding-left:0px" value="checked" unchecked-value="not_checked" name="dataGoodsTerms_ck" class="check-col" :class="{ isInvalid: dataGoodsTerms_ck_is_Invalid }" @input="beforeTabSwitch2()">
                          {{ $t('register_goods_and_tran.terms.terms_ck') }}
                        </b-form-checkbox>
                        <b-form-invalid-feedback id="terms_ck-feedback" :style="{ display: dataGoodsTerms_ck_is_Invalid ? 'block' : 'none' }">{{ $t('register_goods_and_tran.terms.invalid.terms_ck') }}</b-form-invalid-feedback>
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                </tab-content>
                <tab-content :title="title3" :before-change="beforeTabSwitch3">
                  <!-- <ValidationObserver ref="register_goods_and_tran_tab3">
                    <div :style="{ display: display_tab3 ? 'block' : 'none' }">
                      <h4>
                        {{ $t('register_goods_and_tran.add_car.topic') }}
                        <span class="font-color-blue">{{ $t('register_goods_and_tran.add_car.topic2') }}</span>
                        {{ $t('register_goods_and_tran.add_car.topic3') }}
                        <span class="font-color-blue">{{ $t('register_goods_and_tran.add_car.topic4') }}</span>
                        {{ $t('register_goods_and_tran.add_car.topic5') }}
                      </h4>
                      <br />
                      <b-row v-for="(item, index) in dataNewTruck" :key="item">
                        <b-col lg="3">
                          <div class="form-group">
                            <label for="mover_province">{{ $t('register_goods_and_tran.add_car.addNewTruck.group') }} </label>
                            <multiselect v-model="dataNewTruck[index].group" :label="LangTruckTypeGroup" :options="dataTruckTypeGroup[index]" :placeholder="placeholder.group" />
                          </div>
                        </b-col>
                        <b-col lg="3">
                          <div class="form-group">
                            <label for="mover_province">{{ $t('register_goods_and_tran.add_car.addNewTruck.type') }} </label>
                            <multiselect v-model="dataNewTruck[index].type" :label="LangTruckType" :options="dataTruckType[index]" :placeholder="placeholder.type" />
                          </div>
                        </b-col>
                        <b-col lg="3">
                          <div class="form-group">
                            <label for="numberOfCar">{{ $t('register_goods_and_tran.add_car.addNewTruck.numberOfCar') }} </label>
                            <div class="input-group">
                              <b-form-input id="numberOfCar" v-model="dataNewTruck[index].numberOfCar" :placeholder="placeholder.numberOfCar" type="number" class="add_car form-control" name="numberOfCar" />
                              <div class="input-group-prepend">
                                <span class="add_car input-group-text">{{ placeholder.car }}</span>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="3">
                          <div class="form-group">
                            <label for="payload">{{ $t('register_goods_and_tran.add_car.addNewTruck.payload') }} </label><span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;*</span>
                            <div class="input-group add_car">
                              <b-form-input id="payload" v-model="dataNewTruck[index].payload" type="number" class="add_car form-control" name="payload" :placeholder="placeholder.numberOfCar" :class="{ isInvalid: dataNewTruck[index].invalid }" @input="ckInvaildPayload(index)" />
                              <div class="input-group-prepend">
                                <span class="add_car input-group-text" :class="{ isInvalid: dataNewTruck[index].invalid }">{{ placeholder.tons }}</span>
                              </div>
                              <b-form-invalid-feedback id="payload-feedback" :style="{ display: dataNewTruck[index].invalid ? 'block' : 'none' }">{{ $t('register_goods_and_tran.add_car.addNewTruck.payload_invaild') }}</b-form-invalid-feedback>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <a class="add_car_btn" @click="addNewVehicle">{{ $t('register_goods_and_tran.general.btn.add_car') }}</a>
                          <a class="backButton" style="margin-left:2rem" @click="delDataTruck()">{{ del_btn }}</a>
                        </b-col> -->
                  <!-- <b-col>
                        <a class="add_car_btn" @click="testTruckData">test</a>
                      </b-col> -->
                  <!-- </b-row>
                      <br />
                      <hr />
                    </div>
                  </ValidationObserver> -->
                  <div :style="{ display: display_tab4 ? 'block' : 'none' }">
                    <h4>
                      {{ $t('register_goods_and_tran.service_route.topic') }}
                    </h4>
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2412@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <p class="font-color-blue">ภาคกลาง</p>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[2]" :key="option.id" md="3">
                            <template v-if="data_prov_data[2][index].id == '102'">
                              <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(2,option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(2)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2417@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <p class="font-color-blue">ภาคเหนือ</p>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[1]" :key="option.id" md="3">
                            <template v-if="data_prov_data[1][index].id == '101'">
                              <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(1,option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(1)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2414@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <p class="font-color-blue">ภาคใต้</p>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[6]" :key="option.id" md="3">
                            <template v-if="data_prov_data[6][index].id == '106'">
                              <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(6,option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(6)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2419@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <span class="font-color-blue">ภาคอีสาน</span>
                          <span class="font-color-blue" style="font-size:16px">(ตะวันออกเฉียงเหนือ)</span>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[3]" :key="option.id" md="3">
                            <template v-if="data_prov_data[3][index].id == '103'">
                              <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(3, option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(3)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2422@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <p class="font-color-blue">ภาคตะวันออก</p>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[5]" :key="option.id" md="3">
                            <template v-if="data_prov_data[5][index].id == '105'">
                              <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(5,option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(5)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                    <b-row>
                      <b-col lg="2">
                        <b-row class="d-flex justify-content-center">
                          <img src="@/assets/images/thailand pic/Group 2413@2x.png" height="140px" class="text-center" />
                        </b-row>
                        <b-row class="d-flex justify-content-center text-service-route">
                          <br />
                          <p class="font-color-blue">ภาคตะวันตก</p>
                        </b-row>
                      </b-col>
                      <b-col lg="10">
                        <b-row>
                          <b-col v-for="(option, index) in data_prov_data[4]" :key="option.id" md="3">
                            <template v-if="data_prov_data[4][index].id == '104'">
                              <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(4,option.id)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                            <template v-else>
                              <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(4)">
                                <template v-if="transporter_lang">{{ option.name_th }}</template>
                                <template v-else>{{ option.name_en }}</template>
                              </b-form-checkbox>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                    <br />
                  </div>
                  <div :style="{ display: display_tab5 ? 'block' : 'none' }">
                    <h2>{{ $t('register_goods_owner.goods_type.topic') }}</h2>
                    <br />
                    <b-row>
                      <b-col>
                        <div v-for="(option, index) in dataGoodsType_data" :key="option.goods_type_id">
                          <b-form-checkbox v-if="option.goods_type_id == '1'" v-model="dataGoodsType_sel" value="checked" name="checkbox" class="check-col" @change="ck_all_sel_goods(option)">
                            <template v-if="goods_lang">เลือกทั้งหมด</template>
                            <template v-else>Select All</template>
                          </b-form-checkbox>
                          <b-form-checkbox v-else v-model="dataGoodsType_data[index].goods_type_status" value="checked" name="checkbox" class="check-col" @change="ck_sel_goods(option)">
                            <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                            <template v-else>{{ option.goods_type_name_en }}</template>
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div v-for="(option, index) in dataGoodsType_data2" :key="option.goods_type_id">
                          <div v-if="option.goods_type_name_th == 'อื่นๆ'">
                            <b-row>
                              <b-col lg="2" md="2">
                                <b-form-checkbox v-model="dataGoodsType_data2[index].goods_type_status" value="checked" name="checkbox" class="check-col" @change="ck_sel_goods(option)">
                                  <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                                  <template v-else>{{ option.goods_type_name_en }}</template>
                                </b-form-checkbox>
                              </b-col>
                              <b-col lg="6" md="6">
                                <b-form-input id="goods_type_other" v-model="dataGoodsType_note" type="text" class="form-control" name="goods_type_other" :style="{ display: dataGoodsType_data2[index].goods_type_status == 'checked' ? 'block' : 'none' }" />
                              </b-col>
                            </b-row>
                          </div>
                          <div v-else>
                            <b-form-checkbox v-model="dataGoodsType_data2[index].goods_type_status" value="checked" name="checkbox" class="check-col" @change="ck_sel_goods(option)">
                              <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                              <template v-else>{{ option.goods_type_name_en }}</template>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <br />
                    <hr />
                  </div>
                </tab-content>
                <tab-content :title="title4">
                  <h2>{{ $t('register_goods_and_tran.doc.topic') }}</h2>
                  <h4>
                    <span class="font-color-blue">{{ $t('register_goods_and_tran.doc.topic2') }} </span>{{ $t('register_goods_and_tran.doc.topic3') }}<span class="font-color-blue"> {{ $t('register_goods_and_tran.doc.topic4') }} </span>{{ $t('register_goods_and_tran.doc.topic5') }}
                  </h4>
                  <br />
                  <b-row>
                    <b-col style="border-radius:5px;">
                      <!-- <div style="padding:20px; background-color:#F7F7F7; border-radius:5px;">
                        <h4><span class="font-color-blue">{{ $t('register_goods_and_tran.doc.id_card_copy') }}</span></h4>
                        <br />
                        <b-form-file v-model="file_id_card" :placeholder="placeholder.id_card_copy" drop-placeholder="Drop file here..." />
                      </div> -->
                      <vue-dropzone id="dropzone_id_card" ref="dropzone_id_card_copy" :options="dropzoneOptions" :use-custom-slot="true" @input="testFileReader()">
                        <div class="dropzone-custom-content">
                          <h3 v-if="mover_type_sel.value == '1'" class="dropzone-custom-title">{{ $t('register_goods_and_tran.doc.id_card_copy') }}</h3>
                          <h3 v-else class="dropzone-custom-title">{{ $t('register_goods_and_tran.doc.id_card_copy2') }}</h3>
                          <div class="subtitle">{{ $t('register_goods_and_tran.doc.id_card_copy_sub') }}</div>
                        </div>
                      </vue-dropzone>
                      <!-- <div class="text-center mt-4">
                        <button type="button" class="btn btn-primary" @click="testFileReader()">Send Files</button>
                      </div> -->
                    </b-col>
                  </b-row>
                  <br />
                  <br />
                  <b-row>
                    <b-col>
                      <!-- <div style="padding:20px; background-color:#F7F7F7; border-radius:5px;">
                        <h4><span class="font-color-blue">{{ $t('register_goods_and_tran.doc.pic') }}</span></h4>
                        <br />
                        <b-form-file v-model="file_pic_company" :placeholder="placeholder.pic" drop-placeholder="Drop file here..." />
                      </div> -->
                      <vue-dropzone id="dropzone_pic_mover" ref="dropzone_pic_mover" :options="dropzoneOptions2" :use-custom-slot="true" @vdropzone-sending="testFileReader()">
                        <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title">{{ $t('register_goods_and_tran.doc.pic') }}</h3>
                          <div class="subtitle">{{ $t('register_goods_and_tran.doc.pic_sub') }}</div>
                        </div>
                      </vue-dropzone>
                    </b-col>
                    <br />
                  </b-row>
                  <br />
                  <hr />
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <b-row>
                    <b-col class="text-left">
                      <div>
                        <wizard-button v-if="!props.isLastStep" class="wizard-btn" :style="props.fillButtonStyle" @click.native="props.nextTab()">{{ nextButtonText }}</wizard-button>
                        <wizard-button v-else class="finish-button wizard-btn" :style="props.fillButtonStyle" @click.native="sendDataRegister"> {{ props.isLastStep ? fin_btn : nextButtonText }}</wizard-button>
                        <!-- <wizard-button v-if="props.activeTabIndex > 0" style="margin-left:2rem" :style="props.fillButtonStyle" @click.native="props.prevTab()">{{ backButtonText }}</wizard-button> -->
                        <!-- <a v-if="props.activeTabIndex == 2 && (display_tab4 || display_tab5) && !display_tab3 " class="backButton" style="margin-left:2rem" @click="backTabSwitch3()">{{ backButtonText }}</a> -->
                        <a v-if="props.activeTabIndex == 2 && display_tab5 " class="backButton" style="margin-left:2rem" @click="backTabSwitch3()">{{ backButtonText }}</a>
                        <a v-else class="backButton" style="margin-left:2rem" @click="props.prevTab()">{{ backButtonText }}</a>
                      </div>
                      <!-- <div>
                        <wizard-button v-if="!props.isLastStep" class="wizard-footer-right wizard-btn" :style="props.fillButtonStyle" @click.native="props.nextTab()">{{ nextButtonText }}</wizard-button>
                        <wizard-button v-else class="wizard-footer-right finish-button wizard-btn" :style="props.fillButtonStyle" @click.native="sendDataGoodsOwner"> {{ props.isLastStep ? fin_btn : nextButtonText }}</wizard-button>
                      </div> -->
                    </b-col>
                  </b-row>
                </template>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </Layout>
</template>
<style>
.multiselect__placeholder {
  color: #74788d !important;
  font-size: 0.9rem;
}
/* input.custom-control-input::before {
  transform: scale(5);
}
input.custom-control-input {
  transform: scale(5);
} */
/* input#zipcode {
  background-color: #f7f7f7;
} */
input#customCheck1:disabled {
  background-color: #f7f7f7;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f7f7f7;
}
.isInvalid {
  border-color: #ff3d60;
}
.custom-checkbox .isInvalid .custom-control-label::before {
  border: 1px solid #ff3d60 !important;
}
.isInvalid > .multiselect__tags {
  border: 1px solid #ff3d60 !important;
  border-radius: 5px;
}
.multiselect--active.isInvalid > .multiselect__tags {
  transition: 0.35s;
  border: 1px solid #ced4da !important;
  border-radius: 5px;
}
.check-col {
  margin-bottom: 1rem;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f7f7;
}
.vue-form-wizard .navbar .navbar-nav > li > a.wizard-btn,
.vue-form-wizard .wizard-btn {
  min-width: 125px;
}
hr {
  border: 1px solid #e4e4e4;
}
.is_Invalid_nextbtn {
  display: none;
}
.backButton {
  font-size: 14px;
  font-weight: 500;
  color: #03aeef;
  cursor: default;
}
.backButton:hover {
  cursor: pointer;
}
.wizard-btn:hover {
  background-color: #0277a3;
}

.vue-dropzone:hover .dropzone-custom-title {
  color: #03aeef;
}

.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: #fff;
  margin-left: 5px;
  padding: 5px;
  top: inherit;
  bottom: 15px;
  border: 2px #fff solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
.add_car_btn {
  font-size: 24px;
  font-weight: 500;
  color: #03aeef;
  cursor: default;
}
.add_car_btn:hover {
  cursor: pointer;
}
#numberOfCar.add_car,
#payload.add_car {
  border-right: 0px;
}
.add_car.input-group-text {
  background-color: white;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-left: 0px;
}
.add_car.input-group-text.isInvalid {
  border-color: #ff3d60;
}
.text-service-route {
  margin-top: 1rem;
  font-size: 24px;
  font-weight: 500;
}
.custom-card-body {
  min-height: 690px;
}
.vue-form-wizard .wizard-tab-content {
  min-height: 500px;
}
.policy_area {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  padding-left: 60px;
}
</style>
